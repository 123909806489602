import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Components
import ProjectBox from "../Elements/ProjectBox";
import FullButton from "../Buttons/FullButton";
// Assets
import ProjectImg1 from "../../assets/img/products/1.png";
import ProjectImg2 from "../../assets/img/products/2.png";
import ProjectImg3 from "../../assets/img/products/3.png";
import ProjectImg4 from "../../assets/img/products/4.png";
import ProjectImg5 from "../../assets/img/products/5.png";
import ProjectImg6 from "../../assets/img/products/6.png";
import AddImage2 from "../../assets/img/add/2.png";

export default function Projects() {
  return (
    <Wrapper id="projects">
      <div className="blackBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">
              Descubre todo lo que puedes hacer con SAGE 50
            </h1>
            <p className="font13">
              Encuentra la solución que mejor se adapte a tu negocio o empresa,
              <br />
              desde 1 usuario a más de 50!
            </p>
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg1}
                title="Sage 50 contabilidad y facturación"
                text="Ganarás tiempo y agilidad al realizar los procesos de facturación y contabilidad desde una sola aplicación. Generarás tus facturas, presupuestos, albaranes, pedidos y certificaciones de obra de modo personalizado."
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg2}
                title="Comunicación bancaria"
                text="Cuadrarás tus movimientos bancarios con tu contabilidad de una forma más profesional, rápida y sin cometer errores. 

                Ahorra tiempo en el proceso de descarga de movimientos al realizar el proceso de forma automática en todos los bancos."
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg3}
                title="Gestión de compras, ventas y Control de Stock"
                text="Gestiona proveedores y clientes manteniendo el stock en tiempo real, crea pedidos rápidamente para evitar roturas de stock y mantener siempre la eficacia y la satisfacción de tus clientes."
              />
            </div>
          </div>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg4}
                title="Visión total del negocio"
                text="Nuevo sistema de informes y KPI’s que te permiten obtener información clave de tu negocio de un sólo vistazo y desde cualquier dispositivo."
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg5}
                title="Sage 50 TPV"
                text="El programa ofrece la oportunidad de crear estaciones de trabajo para cobrar a los clientes."
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={ProjectImg6}
                title="Sage 50 Servicios y RRHH"
                text="Incorpora potentes componentes orientados a tu tipo de negocio si ofreces servicios, además de un control efectivo para tu equipo de RRHH."
              />
            </div>
          </div>
          <div className="row flexCenter">
            <Link
              className="animate pointer"
              to="contact"
              smooth={true}
              offset={-80}
            >
              <div style={{ margin: "50px 0", width: "200px" }}>
                <FullButton title="Solicita tu demo" />
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="lightBg">
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddLeft>
              <AddLeftInner>
                <ImgWrapper className="flexCenter">
                  <img className="radius8" src={AddImage2} alt="add" />
                </ImgWrapper>
              </AddLeftInner>
            </AddLeft>
            <AddRight>
              <h4 className="font15 semiBold greenColor">Si tienes interés</h4>
              <h2 className="font40 extraBold greenColor">Solicita tu demo</h2>
              <p className="font12 blackColor">
                Nuestro consultor especialista de SAGE 50 te contactará para
                estudiar de forma personalizada todo lo que necesites. Además
                dispondrás de una demo del programa a medida, para que veas un
                ejemplo real de tu negocio!
              </p>
              <ButtonsRow
                className="flexNullCenter"
                style={{ margin: "30px 0" }}
              >
                <Link
                  className="animate pointer"
                  to="contact"
                  smooth={true}
                  offset={-80}
                >
                  <div style={{ width: "190px" }}>
                    <FullButton title="Solicita una demo gratuita!" />
                  </div>
                </Link>
              </ButtonsRow>
            </AddRight>
          </Advertising>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
