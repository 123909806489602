import React from "react";
import styled from "styled-components";
// Assets
import { FaPhone, FaMailBulk } from "react-icons/fa";
import ContactImg1 from "../../assets/img/contact-1.png";
import ContactImg3 from "../../assets/img/25anys.png";

const PhoneButton = () => {
  const handleClick = () => {
    window.location.href = "tel:+34937075955";
  };

  return <Button onClick={handleClick}>LLAMADA</Button>;
};

const EmailButton = () => {
  const handleClick = () => {
    window.location.href = "mailto:sage50erp@satmaticgroup.com";
  };

  return <Button onClick={handleClick}>EMAIL</Button>;
};

export default function Contact() {
  return (
    <Wrapper id="contact">
      <div className="greenBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font60 extraBold">PRUEBA GRATUITA DE SAGE 50</h1>
            <p className="font20">
              Solicitanos una prueba sin compromiso, un consultor IT de SATMÀTIC
              GROUP se pondrá en contacto en menos de 24h.
              <br />
              <br />
              Nuestro horario de atención es de 9h a 14h y de 15h a 18h, de
              Lunes a Viernes.
            </p>
          </HeaderInfo>
          <Container>
            <Card
              style={{ width: "50%" }}
              className="flexNullCenter flexColumn"
            >
              <TitleContainer>
                <Title>Llamar</Title>
                <Icon>
                  <FaPhone />
                </Icon>
              </TitleContainer>
              <PhoneButton />
            </Card>
            <Card
              style={{ width: "50%" }}
              className="flexNullCenter flexColumn"
            >
              <TitleContainer>
                <Title>Email</Title>
                <Icon>
                  <FaMailBulk />
                </Icon>
              </TitleContainer>
              <EmailButton />
            </Card>
            <DiplayImage className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex">
              <div
                style={{ width: "50%" }}
                className="flexNullCenter flexColumn"
              >
                <ContactImgBox>
                  <img src={ContactImg1} alt="office" className="radius6" />
                </ContactImgBox>
              </div>
              <div style={{ width: "50%" }}>
                <div style={{ marginTop: "100px" }}>
                  <img src={ContactImg3} alt="office" className="radius6" />
                </div>
              </div>
            </DiplayImage>
          </Container>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 100px 0 50px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 10px 0 150px 0;
  display: flex;
`;

const Card = styled.div`
  width: 100%;
  height: 150px;
  border-radius: 10px;
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h2`
  color: #000;
`;

const Icon = styled.div`
  font-size: 1.5rem;
  color: #00d639;
  padding-left: 10px;
`;

const Button = styled.button`
  background-color: #00d639;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  &:hover {
    background-color: #000;
  }
`;

const ContactImgBox = styled.div`
  @media (max-width: 800px) {
    display: none;
  }

  @media (min-width: 801px) {
    max-width: 180px;
    align-self: flex-end;
    margin: 10px 30px 10px 0;
  }
`;

const DiplayImage = styled.div`
  @media (max-width: 1000px) {
    display: none;
  }
`;
