import React from "react";
import styled from "styled-components";
// Components
import PricingTable from "../Elements/PricingTable";

export default function Pricing() {
  return (
    <Wrapper id="pricing">
      <div className="blackBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold whiteColor">Precios de SAGE 50</h1>
            <p className="font13">
              SAGE 50 dispone de 3 sabores principales y todas con adaptación
              local o en la nube,
              <br />
              descubre la versión que mejor se adapte a tu negocio.
            </p>
          </HeaderInfo>
          <TablesWrapper className="flexSpaceNull blackColor">
            <TableBox>
              <PricingTable
                icon="browser"
                price="Desde 11.25€/mes*"
                title="Essential"
                text="* Plan de ayuda del 75% de descuento los 3 primeros meses"
                offers={[
                  { name: "Multipuesto y multiempresa", cheked: true },
                  {
                    name: "Contabilidad y facturación integrada",
                    cheked: true,
                  },
                  { name: "Factura electrónica", cheked: true },
                  {
                    name: "Gestión integrada de punto de venta, ventas, compras y almacenes/stock",
                    cheked: true,
                  },
                  { name: "Amortizaciones", cheked: true },
                ]}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                icon="browser"
                price="Desde 27€/mes*"
                title="Standard"
                text="* Plan de ayuda del 75% de descuento los 3 primeros meses"
                offers={[
                  { name: "Asientos predefinidos y periódicos", cheked: true },
                  { name: "Modelos de IRPF", cheked: true },
                  { name: "Contabilidad analítica ", cheked: true },
                  { name: "Conciliación bancaria", cheked: true },
                  { name: "Listado de Cashflow", cheked: true },
                ]}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                icon="browser"
                price="Desde 40€/mes*"
                title="Premium"
                text="* Plan de ayuda del 75% de descuento los 3 primeros meses"
                offers={[
                  { name: "Certificaciones de Obra", cheked: true },
                  { name: "Contabilidad presupuestaria", cheked: true },
                  { name: "Gestión de Envases", cheked: true },
                  { name: "Escaneo de firma en albaranes", cheked: true },
                  { name: "Cambios de estado en el patrimonio", cheked: true },
                ]}
              />
            </TableBox>
          </TablesWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
`;
const HeaderInfo = styled.div`
  margin-bottom: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const TablesWrapper = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const TableBox = styled.div`
  width: 31%;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto;
  }
`;
