import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Assets
import LogoSatmatic from "../../assets/img/satmatic/satmatic.png";
import LogoIcon from "../../assets/svg/Logo";

export default function Contact() {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <Wrapper>
      <div className="darkBg">
        <div className="container">
          <InnerWrapper
            className="flexSpaceCenter"
            style={{ padding: "30px 0" }}
          >
            <div>
              <LogoIcon />
              <a href="https://www.satmaticgroup.com/es/">
                <img
                  src={LogoSatmatic}
                  alt="satmaticgroup"
                  className="mi-logo"
                />
              </a>
            </div>
            <StyleP className="whiteColor font13">
              © {getCurrentYear()} -{" "}
              <a
                className="orangeColor font13"
                href="https://www.satmaticgroup.com/es/"
              >
                SATMÀTIC GROUP
              </a>{" "}
              Todos los derechos reservados
            </StyleP>

            <Link
              className="orangeColor animate pointer font13"
              to="home"
              smooth={true}
              offset={-80}
            >
              Volver a inicio
            </Link>
          </InnerWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const StyleP = styled.p`
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;
